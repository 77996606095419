import { tns } from "tiny-slider/src/tiny-slider";
import { sizes } from "./utils/mq";

var PageSliders;

function initSlider() {
  var sliders = document.querySelectorAll(".slider");
  if (sliders.length) {
    for (var i = 0; i < sliders.length; i++) {
      if (sliders[i].children.length == 0) continue;
      let sliderType = sliders[i].getAttribute("data-slider");
      let sliderDirection = sliders[i].getAttribute("data-direction");
      let start = 0;
      let slider, container, controls;
      let containerParent = sliders[i].parentElement;

      container = sliders[i];

      if (sliderType == "slideshow" || sliderType == "hero__news") {
        if (containerParent) {
          controls = containerParent.querySelectorAll(
            "." + sliderType + "-panel__controls"
          );
        }
      } else {
        if (containerParent) {
          controls = containerParent.querySelectorAll(
            ".carousel-panel__controls"
          );
        }
      }

      let slideCount = container.querySelectorAll("article").length;

      if (sliderDirection == "rtl") {
        start = slideCount;
      }

      const baseOptions = {
        container: container,
        items: 1,
        nav: false,
        mouseDrag: true,
        loop: false,
        controlsContainer: controls[0],
        controlsPosition: "bottom",
        slideBy: 1,
        preventScrollOnTouch: "auto",
        swipeAngle: 30
      };

      if (sliderType == "slideshow") {
        slider = tns({
          ...baseOptions
        } as any);
      } else if (sliderType == "hero__news") {
        slider = tns({
          ...baseOptions,
          items: 2
        } as any);
      } else if (sliderType == "summary") {
        slider = tns({
          ...baseOptions,
          items: 2
        } as any);
      } else if (sliderType == "news") {
        slider = tns({
          ...baseOptions,
          items: 1,
          responsive: {
            [sizes.L]: {
              items: 3
            }
          }
        } as any);
      } else if (sliderType == "background") {
        slider = tns({
          ...baseOptions,
          mode: "gallery",
          autoplay: true,
          loop: true,
          controls: false,
          autoplayButtonOutput: false,
          speed: 1500
        } as any);
      } else {
        slider = tns({
          ...baseOptions,
          center: false,
          responsive: {
            [sizes.L]: {
              items: 2
            }
          }
        } as any);
      }

      slider.goTo(start);

      var info = slider.getInfo();

      // NOTE...Touch events will throw an error, issue here:
      // https://github.com/ganlanyuan/tiny-slider/issues/370

      // Set active slide on load
      if (sliderDirection == "rtl") {
        info.slideItems[info.slideCount - 1].classList.add("active");
      } else {
        info.slideItems[0].classList.add("active");
      }

      // Set active slide after indexChanged
      var move = function() {
        var info = slider.getInfo(),
          indexCached = info.indexCached,
          indexCurrent = info.index;
        info.slideItems[indexCached].classList.remove("active");
        info.slideItems[indexCurrent].classList.add("active");
      };

      var removeExtra = function() {
        var extra = document.querySelector(".carousel-panel__extra");
        var extraTwo = document.querySelector(".carousel-panel__extraTwo");
        let viewportWidth = window.innerWidth;
        if (extra != null && extraTwo != null) {
          if (viewportWidth < 980) {
            console.log("test");
            extra.remove();
            extraTwo.remove();
          }
          return;
        }
      };
      removeExtra();

      slider.events.on("indexChanged", move);

      if (PageSliders == undefined) {
        PageSliders = [slider];
      } else {
        PageSliders.push(slider);
      }
    }
  }
}

export { initSlider as InitSlider, PageSliders };
