import { h, RenderableProps } from "preact";
import { CroppedImage, ICroppedImageProps } from "./../croppedImage";

export interface IReviewArticleProps {
  title: string;
  intro: string;
  author: string;
  institution: string;
  url: string;
  croppedImage: ICroppedImageProps;
  hasImage: boolean;
}

export const ReviewArticle = ({
  title,
  intro,
  author,
  institution,
  url,
  croppedImage,
  hasImage
}: RenderableProps<IReviewArticleProps>) => (
  <li class="card-list__item card">
    <div class="card__asset">
      {hasImage ? (
        <CroppedImage {...croppedImage} />
      ) : (
        <div class="placeholder">
          <svg class="icon placeholder-image">
            <use xlinkHref="/img/dist/sprite.svg#acu-logo" />
          </svg>
        </div>
      )}
    </div>

    <div class="card__content">
      <h5 class="card__heading">
        <a class="card__heading-link" href={url}>
          {title}
        </a>
      </h5>
      <span class="card__description">{intro}</span>
      {author != null && author.length > 0 ? (
        <div>
          <br />
          <span class="card__description">
            By <strong>{author}</strong>
          </span>
          <br />
          <span class="card__description">{institution}</span>
        </div>
      ) : (
        ""
      )}
    </div>
  </li>
);

export default ReviewArticle;
