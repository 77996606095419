import { h } from "preact";

export interface ICroppedImageProps {
  source: string;
  dataSource: string;
  altText: string;
  imageWidth: number;
  imageHeight: number;
  imageSources: Array<IPictureSource>;
}

export interface IPictureSource {
  mediaQuery: string;
  sourceSet: string;
  mimeType: string;
}

export const CroppedImage = ({
  source,
  altText,
  imageSources,
  imageWidth,
  imageHeight
}: ICroppedImageProps) => (
  <picture>
    {imageSources.map(src => (
      <source
        data-sizes="auto"
        media={src.mediaQuery}
        data-srcset={src.sourceSet}
        type={src.mimeType}
      />
    ))}
    <img
      width={imageWidth}
      height={imageHeight}
      data-sizes="auto"
      class="lazyload"
      data-src={source}
      alt={altText}
    />
  </picture>
);

export default CroppedImage;
