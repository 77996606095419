import svg4everybody from "svg4everybody";
import "lazysizes";
import loadPicturefill from "./utils/load-picturefill";
import noJS from "./utils/nojs";
//import Navigation from "./navigation";
//import cookieConsent from "./cookie-consent";
import siteSearch from "./site-search";
import memberMap from "./member-map";
import memberDirectory from "./member-directory";
import publications from "./publications";
import { InitSlider } from "./slider";
import videoPanel from "./video-panel";
import objectFitImages from "object-fit-images";
import reviews from "./reviews";
import truncation from "./truncation";
import initSummarySlider from "./summary-card-slider";
import newNav from "./new-nav";
import mobileNav from "./mobile-nav";
import stickyNav from "./sticky-nav";
import overlay from "./overlay";
//import headerNav from "./header-nav";

loadPicturefill();
svg4everybody();
noJS();
//cookieConsent();
//const nav = new Navigation();
//themeNavTrigger(nav);
InitSlider();
initSummarySlider();
videoPanel();
objectFitImages();
newNav();
overlay();
stickyNav(
  document.querySelector(".primary-header"),
  "primary-header--sticky",
  "primary-header--hide",
  true,
  "primary-header--animate"
);
mobileNav(document.querySelectorAll(".primary-navigation__item"));
//headerNav();
truncation();

let searchEl = document.getElementById("js-search");
if (searchEl) {
  siteSearch(searchEl);
}

let memberMapEl = document.getElementById("js-member-map");
if (memberMapEl) {
  memberMap(memberMapEl);
}

let memberDirectoryEl = document.getElementById("js-member-directory");
if (memberDirectoryEl) {
  memberDirectory(memberDirectoryEl);
}

let publicationsEl = document.getElementById("js-publications");
if (publicationsEl) {
  publications(publicationsEl);
}

let reviewEl = document.getElementById("js-reviews");
if (reviewEl) {
  reviews(reviewEl);
}
