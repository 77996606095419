import { h, RenderableProps } from "preact";
import { IMember } from "../interfaces";

interface IMemberProps {
  member: IMember;
}

const Member = ({ member }: RenderableProps<IMemberProps>) => (
  <div className="directory__member">
    <h4 className="directory__member-name">{member.name}</h4>
    {member.description && (
      <p className="directory__member-description">{member.description}</p>
    )}
    {member.website && (
      <a
        href={member.website}
        target="_blank"
        className="directory__member-link"
      >
        Link to website
      </a>
    )}
  </div>
);

export default Member;
