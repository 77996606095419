function ovelayMenu() {
  console.log("test");
  let item = document.getElementsByClassName(
    "primary-navigation__item--sub-nav"
  );
  if (item) {
    for (let i = 0; i < item.length; i++) {
      item[i].addEventListener("mouseenter", function(event) {
        event.preventDefault();
        console.log("hello");
        let overlay = document.querySelector(".overlay") as HTMLElement;
        overlay.classList.add("overlay--visible");
      });
    }
    for (let i = 0; i < item.length; i++) {
      item[i].addEventListener("mouseleave", function(event) {
        event.preventDefault();
        console.log("hello");
        let overlay = document.querySelector(".overlay") as HTMLElement;
        overlay.classList.remove("overlay--visible");
      });
    }
  }
}

export default ovelayMenu;
