import { h, RenderableProps } from "preact";
import { IRenderCountry } from "../interfaces";

import Member from "./member";

interface ICountryProps {
  country: IRenderCountry;
}

const Country = ({ country }: RenderableProps<ICountryProps>) => (
  <div className="directory__country">
    <h3 className="directory__country-heading">
      <img
        src={`/img/dist/norev/flags/${country.code.toLowerCase()}.png`}
        alt={`${country.name} flag`}
        className="directory__country-flag"
      />{" "}
      {country.name}
    </h3>
    {country.members.map(m => (
      <Member key={m.id} member={m} />
    ))}
  </div>
);

export default Country;
