import { h, RenderableProps } from "preact";

interface ISVGIconProps {
  spriteUrl?: string;
  name: string;
  className?: string;
}

const SVGIcon = ({
  name,
  className = "",
  spriteUrl = "/img/dist/sprite.svg"
}: RenderableProps<ISVGIconProps>) => (
  <svg className={`icon ${className}`}>
    <use xlinkHref={`${spriteUrl}#${name}`} />
  </svg>
);

export default SVGIcon;
