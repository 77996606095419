declare const mapboxgl: typeof import("mapbox-gl");

import { h, Component } from "preact";
import MultiTouch from "mapbox-gl-multitouch";
import { IMember, IRegion } from "../interfaces";
import mq from "../utils/mq";
import { GeoJSONSource } from "mapbox-gl";

interface IMemberMapProps {
  accessToken: string;
  styleUrl: string;
  members: IMember[];
  regions: IRegion[];
  selectedRegion?: IRegion;
}

class MemberMap extends Component<IMemberMapProps> {
  mapContainer: Element | null;
  map: mapboxgl.Map | null;
  regionLookup: { [countryCode: string]: IRegion } = {};

  constructor(props) {
    super(props);
    this.mapContainer = null;
    this.map = null;
  }

  static getPopupHtml(member: IMember) {
    return `<div class="map__popup-inner"><h4 class="map__popup-heading">${
      member.name
    }</h4></div>`;
  }

  fitMapToMembers(map: mapboxgl.Map, members: IMember[]) {
    const bounds: mapboxgl.LngLatBounds = members.reduce(
      (bounds, m) =>
        bounds.extend(
          new mapboxgl.LngLat(m.latLng.longitude, m.latLng.latitude)
        ),
      new mapboxgl.LngLatBounds()
    );
    map.fitBounds(bounds, { padding: 30 });
  }

  createPopup(m: IMember) {
    // Offsets for the popup positioning
    // markerHeight/-Width is the rough height/width on the marker image,
    // offsets have been set manually
    let markerHeight = 28,
      markerWidth = 19;
    let popupOffsets = {
      top: new mapboxgl.Point(0, 0),
      "top-left": new mapboxgl.Point(0, 0),
      "top-right": new mapboxgl.Point(0, 0),
      bottom: new mapboxgl.Point(0, -markerHeight),
      "bottom-left": new mapboxgl.Point(markerWidth / 2, -markerHeight),
      "bottom-right": new mapboxgl.Point(-markerWidth / 2, -markerHeight),
      left: new mapboxgl.Point(markerWidth / 2, -markerHeight / 2),
      right: new mapboxgl.Point(-markerWidth / 2, -markerHeight / 2)
    };

    const popup = new mapboxgl.Popup({
      offset: popupOffsets,
      // @ts-ignore
      maxWidth: mq("M") ? undefined : "200px",
      className: "map__popup",
      closeButton: false
    }).setHTML(MemberMap.getPopupHtml(m));

    return popup;
  }

  setUpMapControls(map: mapboxgl.Map) {
    // Add zoom controls to the map, because scroll zoom is disabled
    var nav = new mapboxgl.NavigationControl({
      showCompass: false // Hide rotation control.
    });
    map.addControl(nav, "bottom-right");

    // Third party fix to disable touch pan - https://github.com/bravecow/mapbox-gl-multitouch
    map.addControl(new MultiTouch());
  }

  setUpMapSource(map: mapboxgl.Map, geojson: GeoJSON.FeatureCollection) {
    map.addSource("members", {
      type: "geojson",
      data: geojson,
      cluster: true,
      clusterRadius: 50
    });
  }

  setUpClustering(map: mapboxgl.Map) {
    const iconSizes: mapboxgl.Expression | number = mq("M")
      ? [
          "step",
          ["get", "point_count"],
          0.5, // for 0-10 markers in a cluster set size to 0.5
          10,
          0.6, // for 10+ markers in a cluster set size to 0.6
          20, // for 20+ markers in a cluster
          0.7
        ]
      : 0.45; // for 20+ markers in a cluster

    const textSizes: mapboxgl.Expression | number = mq("M")
      ? ["step", ["get", "point_count"], 14, 5, 16, 10, 18]
      : 14;

    map.addLayer({
      id: "clusters",
      type: "symbol",
      source: "members",
      filter: ["==", "cluster", true],
      layout: {
        "icon-image": "marker",
        "icon-anchor": "center",
        "icon-size": iconSizes,
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "text-field": "{point_count_abbreviated}",
        "text-size": textSizes,
        "text-font": ["Open Sans Bold"],
        "text-anchor": "top",
        "text-offset": [0, -0.9]
      },
      paint: {
        "text-color": "#fff"
      }
    });

    map.addLayer({
      id: "members",
      type: "symbol",
      source: "members",
      filter: ["!=", "cluster", true],
      layout: {
        "icon-image": "marker",
        "icon-anchor": "bottom",
        "icon-size": 0.3,
        "icon-allow-overlap": true,
        "icon-ignore-placement": true
      }
    });

    // inspect a cluster on click
    map.on("click", "clusters", e => {
      var features = map.queryRenderedFeatures(e.point, {
        layers: ["clusters"]
      });
      var clusterId =
        features[0] &&
        features[0].properties &&
        features[0].properties.cluster_id;
      const membersSource = map.getSource("members") as mapboxgl.GeoJSONSource;

      membersSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;
        const point = features[0].geometry as GeoJSON.Point;
        map.easeTo({
          center: point.coordinates as [number, number],
          zoom: zoom
        });
      });
    });

    map.on("click", "members", e => {
      if (!e.features || !e.features[0]) {
        return;
      }
      var geometry = e.features[0].geometry as GeoJSON.Point;
      var coordinates = geometry.coordinates.slice() as [number, number];

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      var props = e.features[0].properties as any;
      var member = JSON.parse(props.member);
      this.createPopup(member)
        .setLngLat(coordinates)
        .addTo(map);
      map.panTo(coordinates); // centre on pin
    });

    map.on("mouseenter", "clusters", () => {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", "clusters", () => {
      map.getCanvas().style.cursor = "";
    });
    map.on("mouseenter", "members", () => {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", "members", () => {
      map.getCanvas().style.cursor = "";
    });
  }

  getGeoJsonForMembers = (members: IMember[]): GeoJSON.FeatureCollection => {
    return {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" }
      },
      features: members.map((m: IMember) => {
        const region: IRegion | undefined = this.regionLookup[m.country.code];
        const regionName: string | undefined = region && region.name;
        return {
          type: "Feature",
          properties: {
            member: m,
            id: m.id,
            region: regionName
          },
          geometry: {
            type: "Point",
            coordinates: [m.latLng.longitude, m.latLng.latitude, 0.0]
          }
        };
      })
    } as GeoJSON.FeatureCollection;
  };

  setUpRegionLookup = () => {
    for (let region of this.props.regions) {
      for (let country of region.countries) {
        this.regionLookup[country.code] = region;
      }
    }
  };

  setUpMap() {
    if (!this.mapContainer) return;

    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: this.props.styleUrl,
      center: new mapboxgl.LngLat(30, 6),
      zoom: 1.4,
      attributionControl: false,
      scrollZoom: false
      //renderWorldCopies: false
    });
    this.setUpMapControls(map);
    map.on("load", () => {
      this.setUpMapSource(map, this.getGeoJsonForMembers(this.props.members));
      this.setUpClustering(map);
    });
    this.fitMapToMembers(map, this.props.members);
    this.map = map;
  }

  componentDidUpdate(prevProps: IMemberMapProps) {
    if (!this.map) {
      return;
    }
    if (prevProps.selectedRegion !== this.props.selectedRegion) {
      let members: IMember[] = this.props.members;
      if (this.props.selectedRegion) {
        const { selectedRegion } = this.props;
        members = this.props.members.filter(
          m =>
            !!this.regionLookup[m.country.code] &&
            this.regionLookup[m.country.code].name === selectedRegion.name
        );
      }
      (this.map.getSource("members") as GeoJSONSource).setData(
        this.getGeoJsonForMembers(members)
      );
      this.fitMapToMembers(this.map, members);
    }
  }

  componentDidMount() {
    mapboxgl.accessToken = this.props.accessToken || "";
    this.setUpRegionLookup();
    this.setUpMap();
  }

  render() {
    return <div className="map" ref={el => (this.mapContainer = el)} />;
  }
}

export default MemberMap;
