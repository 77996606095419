import { h, RenderableProps } from "preact";
import { IRenderRegion } from "../interfaces";

import Country from "./country";

interface IRegionProps {
  region: IRenderRegion;
}

const Region = ({ region }: RenderableProps<IRegionProps>) => (
  <div className="directory__region">
    <h2 className="directory__region-heading">
      <span className="directory__region-heading-text">{region.name}</span>
    </h2>
    {region.countries.map(c => (
      <Country key={c.code} country={c} />
    ))}
  </div>
);

export default Region;
