import { h, Component, createRef } from "preact";

export interface IReviewNavProps {
  themes: Array<string>;
  selectedTheme: string;
  buttonHandler(e: any): Promise<void>;
}

export interface IReviewNavState {
  isNavExpandable: boolean;
}

export class ReviewNav extends Component<IReviewNavProps, IReviewNavState> {
  ulNode = createRef<HTMLUListElement>();

  constructor(props: IReviewNavProps) {
    super(props);

    this.state = {
      isNavExpandable: false
    };
  }

  componentDidMount() {
    this.toggleExpandOptionVisibility();
  }

  private toggleExpandOptionVisibility() {
    if (!this.ulNode.current) {
      return;
    }

    var contentWidth: number = 0;
    const parentWidth = this.ulNode.current.offsetWidth;
    this.ulNode.current.childNodes.forEach(element => {
      if (!(element instanceof HTMLLIElement)) {
        return;
      }
      contentWidth += element.offsetWidth;
    });
    const fillPerc = contentWidth / parentWidth;
    this.setState({ isNavExpandable: fillPerc > 0.9 });
  }

  render() {
    const { selectedTheme, themes, buttonHandler } = this.props;
    const { isNavExpandable } = this.state;

    return (
      <div>
        <aside class="theme-tabs">
          <input type="checkbox" id="theme-tabs__checkbox" />
          <div class="theme-tabs__inner">
            <ul ref={this.ulNode} class="theme-tabs__tab-list">
              <li
                class={
                  selectedTheme === null || selectedTheme === "All"
                    ? "theme-tabs__tab theme-tabs__tab--all theme-tabs__tab--active"
                    : "theme-tabs__tab theme-tabs__tab--all"
                }
              >
                <a
                  class="theme-tabs__tab-link"
                  href="#"
                  onClick={buttonHandler}
                >
                  All
                </a>
              </li>
              {themes.map((theme, key) => (
                <li key={key} class="theme-tabs__tab">
                  <a
                    class={
                      selectedTheme === theme
                        ? "theme-tabs__tab-link theme-tabs__tab--active"
                        : "theme-tabs__tab-link"
                    }
                    href="#"
                    onClick={buttonHandler}
                  >
                    {theme}
                  </a>
                </li>
              ))}
            </ul>
            <label for="theme-tabs__checkbox" class="theme-tabs__toggle">
              <span class="theme-tabs__toggle-wording--closed-mob">
                Issue{" "}
                <svg class="icon theme-tabs__toggle-icon">
                  <use xlinkHref="/img/dist/sprite.svg#menu" />
                </svg>
              </span>
              <span class="theme-tabs__toggle-wording--open-mob">
                Close{" "}
                <svg class="icon theme-tabs__toggle-icon">
                  <use xlinkHref="/img/dist/sprite.svg#close" />
                </svg>
              </span>
              {isNavExpandable ? (
                <div>
                  <span class="theme-tabs__toggle-wording--closed">
                    More{" "}
                    <svg class="icon theme-tabs__toggle-icon">
                      <use xlinkHref="/img/dist/sprite.svg#arrow-down" />
                    </svg>
                  </span>
                  <span class="theme-tabs__toggle-wording--open">
                    Less{" "}
                    <svg class="icon theme-tabs__toggle-icon">
                      <use xlinkHref="/img/dist/sprite.svg#arrow-down" />
                    </svg>
                  </span>
                </div>
              ) : null}
            </label>
          </div>
        </aside>
        <script />
      </div>
    );
  }
}

export default ReviewNav;
