import { h, RenderableProps } from "preact";

export interface IReviewPaginationProps {
  recordOffset: number;
  recordLimit: number;
  totalRecords: number;
  buttonHandler(e: any): Promise<void>;
}

//paging__button paging__button--number paging__button--active-number

const pagination = (totalRecords: number, recordLimit: number): number[] => {
  let range: number[] = [];

  for (let i = 1; i <= Math.ceil(totalRecords / recordLimit); i++) {
    range.push(i);
  }

  return range;
};

export const ReviewPagination = ({
  recordOffset,
  recordLimit,
  totalRecords,
  buttonHandler
}: RenderableProps<IReviewPaginationProps>) =>
  Math.ceil(totalRecords / recordLimit) > 1 ? (
    <nav class="paging paging--unpad">
      <a
        key="prev"
        class="paging__button paging__button--previous"
        title="Previous Page"
        href="#"
        rel="prev"
        aria-disabled={recordOffset === 0}
        onClick={buttonHandler}
      >
        Prev
      </a>
      {pagination(totalRecords, recordLimit).map((_x, i) => {
        return (
          <a
            key={i}
            class={
              recordOffset / recordLimit === i
                ? "paging__button paging__button--number paging__button--active-number"
                : "paging__button paging__button--number"
            }
            onClick={buttonHandler}
          >
            {i + 1}
          </a>
        );
      })}
      <a
        key="next"
        class="paging__button paging__button--next"
        title="Next Page"
        href="#"
        rel="next"
        aria-disabled={recordOffset + recordLimit >= totalRecords}
        onClick={buttonHandler}
      >
        Next
      </a>
    </nav>
  ) : null;

export default ReviewPagination;
