import { h, render, Component } from "preact";
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";
import "promise-polyfill/dist/polyfill";
import { fetch } from "whatwg-fetch";

// use native browser implementation if it supports aborting
const abortableFetch = "signal" in new Request("") ? window.fetch : fetch;

import debounce from "lodash.debounce";

import { IPaging } from "../interfaces";
import { ISearchResult } from "./interfaces";
import SVGIcon from "../components/svgIcon";
import SearchPaging from "../components/searchPaging";
import SearchLoading from "../components/searchLoading";
import Result from "./result";

interface ISearchProps {
  searchUrl: string;
}

interface ISearchResults {
  query: string;
  results: ISearchResult[];
  paging: IPaging;
}

interface ISearchState {
  focussed: boolean;
  query: string;
  results?: ISearchResults;
  page: number;
  loading: boolean;
}

class Search extends Component<ISearchProps, ISearchState> {
  pageSize = 10;
  inputEl: HTMLInputElement | null = null;
  pagingInfoEl: HTMLInputElement | null = null;
  abortController: AbortController | null = null;
  initialState: ISearchState = {
    focussed: false,
    query: "",
    page: 1,
    results: undefined,
    loading: false
  };

  constructor(props: ISearchProps) {
    super(props);
    this.state = this.initialState;
  }

  handleFocus = () => {
    this.setState({
      focussed: true
    });
  };

  handleClose = () => {
    this.setState(this.initialState);
  };

  handleQueryChange = async () => {
    const query = (this.inputEl && this.inputEl.value) || "";
    if (this.state.query !== query) {
      this.setState({ query, page: 1 });
      await this.fetchResults(query);
    }
  };

  handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      this.handleClose();
    }
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  };

  fetchResults = debounce(async (query: string, page: number = 1): Promise<
    ISearchResults | undefined
  > => {
    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();
    if (query && query.length < 3) {
      this.setState({
        results: undefined
      });
      return Promise.resolve({ query: query } as ISearchResults);
    }

    this.setState({
      loading: true
    });
    let results: ISearchResults | undefined;
    try {
      const response = await abortableFetch(
        `${this.props.searchUrl}?query=${query}&page=${page}&pageSize=${
          this.pageSize
        }`,
        { signal: this.abortController.signal }
      );
      results = (await response.json()) as ISearchResults;
      this.setState({
        results,
        loading: false
      });
    } catch (e) {
      if (e.name !== "AbortError") {
        this.setState({
          loading: false
        });
      }
    }
    return results;
  }, 150);

  handleGoToPage = page => () => {
    if (
      page < 1 ||
      !this.state.results ||
      page > this.state.results.paging.totalPages
    ) {
      return;
    }
    this.setState({ page });
    this.fetchResults(this.state.query, page);
    if (this.pagingInfoEl) {
      this.pagingInfoEl.scrollIntoView({ behavior: "smooth" });
    }
  };

  componentDidUpdate = (_prevProps, prevState: ISearchState) => {
    if (prevState.focussed === false && this.state.focussed === true) {
      document.body.classList.add("body--no-scroll");
    } else if (prevState.focussed === true && this.state.focussed === false) {
      if (this.inputEl) {
        this.inputEl.blur();
      }
      document.body.classList.remove("body--no-scroll");
    }
  };

  renderPagingParagraph = (results: ISearchResults) => {
    const first =
      results.paging.pageSize * (results.paging.currentPage - 1) + 1;
    const last = Math.min(
      first + results.paging.pageSize - 1,
      results.paging.totalResults
    );
    return (
      <p className="search__paging-info" ref={c => (this.pagingInfoEl = c)}>
        Showing results{" "}
        <strong>
          {first} - {last}
        </strong>{" "}
        of <strong>{results.paging.totalResults}</strong> for {results.query}
      </p>
    );
  };

  renderNoResults = (results: ISearchResults) => {
    return (
      <p className="search__info-message">
        There were no results for <strong>{results.query}</strong>
      </p>
    );
  };

  renderResults = (results: ISearchResults) => {
    if (!results.results || !results.results.length) {
      return this.renderNoResults(results);
    }
    return (
      <div>
        {this.renderPagingParagraph(results)}
        <ul role="list">
          {results.results.map(r => (
            <li key={r.url} role="listitem" className="search-result">
              <Result result={r} />
            </li>
          ))}
        </ul>
        <SearchPaging goToPage={this.handleGoToPage} paging={results.paging} />
      </div>
    );
  };

  render() {
    const { focussed, results, query, loading } = this.state;
    return (
      <div className={`search ${focussed ? "search--focussed" : ""}`}>
        <form
          className="search__box constrain constrain--wide"
          role="search"
          onSubmit={e => e.preventDefault()}
        >
          <input
            type="search"
            value={query}
            ref={c => (this.inputEl = c)}
            className="search__input"
            onFocus={this.handleFocus}
            onInput={this.handleQueryChange}
            onKeyUp={this.handleKeyPress}
            autocomplete="off"
            aria-label="Search the site"
            aria-controls="search-results"
          />
          <SVGIcon name="search" className="search__icon" />
          {focussed && (
            <button
              type="button"
              className="search__close"
              onClick={this.handleClose}
            >
              <span className="search__close-text">Close</span>
              <SVGIcon name="close" className="search__close-icon" />
            </button>
          )}
        </form>
        {focussed && (
          <div className="constrain constrain--wide search__results-container">
            <div
              id="search-results"
              className="search__results"
              aria-live="polite"
            >
              <SearchLoading loading={loading} />
              {(!results || !query || query.length < 3) && (
                <p className="search__info-message">
                  Start typing to begin your search
                </p>
              )}
              {results && results.query && this.renderResults(results)}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function initialise(element: HTMLElement) {
  const searchUrl = element.dataset.searchUrl;
  if (!searchUrl) return;

  return render(<Search searchUrl={searchUrl} />, element);
}

export default initialise;
