import { h, RenderableProps } from "preact";

import { IPublication } from "./interfaces";

interface IPublicationProps {
  result: IPublication;
}

const Publication = ({ result }: RenderableProps<IPublicationProps>) => {
  return (
    <a href={result.url} target="_blank" className="search-result__link">
      <div className="search-result__content">
        <h3
          dangerouslySetInnerHTML={{ __html: result.name }}
          className="search-result__title"
        />
        {result.description && (
          <div
            dangerouslySetInnerHTML={{ __html: result.description }}
            className="search-result__description"
          />
        )}
      </div>
      <dl className="search-result__attributes">
        <dt className="search-result__attribute-name">Author: </dt>
        <dd className="search-result__attribute-value">
          {result.author ? result.author : "Unknown"}
        </dd>
        <dt className="search-result__attribute-name">Published: </dt>
        <dd className="search-result__attribute-value">
          {result.publishedDate ? result.publishedDate : "Unknown"}
        </dd>
        <dt className="search-result__attribute-name">Type: </dt>
        <dd className="search-result__attribute-value">
          {result.type ? result.type : "Unknown"}
        </dd>
        {result.theme && (
          <dt className="search-result__attribute-name">Theme: </dt>
        )}
        {result.theme && (
          <dd className="search-result__attribute-value">{result.theme}</dd>
        )}
        {result.tags && (
          <dt className="search-result__attribute-name">Tags: </dt>
        )}
        {result.tags && (
          <dd className="search-result__attribute-value">{result.tags}</dd>
        )}
      </dl>
    </a>
  );
};

export default Publication;
