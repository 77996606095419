function init() {
  //let videoPlayButtons:HTMLCollectionOf<Element>;
  let videoPlayButtons = document.getElementsByClassName("js-video-play");

  if (videoPlayButtons) {
    for (let i = 0; i < videoPlayButtons.length; i++) {
      videoPlayButtons[i].addEventListener("click", event => {
        event.preventDefault();

        const button = videoPlayButtons[i] as HTMLElement;
        if (button && button.dataset.src) {
          let iframe = document.createElement("iframe");
          iframe.src = button.dataset.src;
          iframe.setAttribute("frameborder", "0");
          iframe.setAttribute("allowfullscreen", "");

          const parentElement = button.parentElement;
          if (parentElement) {
            parentElement.classList.add("full-width-video-panel--playing");
            parentElement.appendChild(iframe);
          }
        }
      });
    }
  }
}

export default init;
