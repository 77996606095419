import { h, RenderableProps } from "preact";

import { IPaging } from "../interfaces";

interface ISearchPagingProps {
  goToPage: (page: number) => () => void;
  showNumberLinks?: boolean;
  paging: IPaging;
  className?: string;
}

const pagination = (
  currentPage: number,
  totalPages: number
): (number | string)[] => {
  let current = currentPage;
  let last = totalPages;
  let delta = 2;
  let left = current - delta;
  let right = current + delta + 1;
  let range: number[] = [];
  let rangeWithDots: (number | string)[] = [];
  let l: number | undefined;

  for (let i = 1; i <= last; i++) {
    if (i == 1 || i == last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push("...");
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
};

const renderPageLinks = (
  paging: IPaging,
  goToPage: (page: number) => () => void
) => {
  const pages = pagination(paging.currentPage, paging.totalPages);
  return pages.map(p => {
    if (typeof p === "string") {
      return <span className="paging__ellipsis">&hellip;</span>;
    } else {
      return (
        <button
          className={`unbutton paging__button paging__button--number${
            p === paging.currentPage ? " paging__button--active-number" : ""
          }`}
          onClick={goToPage(p)}
        >
          {p}
        </button>
      );
    }
  });
};

const SearchPaging = ({
  paging,
  showNumberLinks = false,
  goToPage,
  className
}: RenderableProps<ISearchPagingProps>) =>
  paging.totalPages <= 1 ? null : (
    <div className={`paging ${className}`}>
      <button
        className="unbutton paging__button paging__button--previous"
        disabled={paging.currentPage <= 1}
        onClick={goToPage(paging.currentPage - 1)}
      >
        Previous
      </button>
      {showNumberLinks && renderPageLinks(paging, goToPage)}
      <button
        className="unbutton paging__button paging__button--next"
        disabled={paging.currentPage >= paging.totalPages}
        onClick={goToPage(paging.currentPage + 1)}
      >
        Next
      </button>
    </div>
  );

export default SearchPaging;
