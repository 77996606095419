import throttle from "lodash/throttle";

const stickyNav = function(
  stickyElement,
  stickyClass,
  hideClass,
  minimumTrigger,
  animateClass
) {
  let lastScrollTop = 0;
  const top = window.pageYOffset || document.documentElement.scrollTop;
  if (minimumTrigger) {
    if (top > 10) {
      stickyElement.classList.add(stickyClass);
      stickyElement.classList.add(hideClass);
    }
  }
  window.addEventListener(
    "scroll",
    throttle(function() {
      const top = window.pageYOffset || document.documentElement.scrollTop;
      let topVal = 0;
      if (minimumTrigger) {
        topVal = 10;
      }
      if (top < lastScrollTop) {
        stickyElement.classList.add(stickyClass);
        stickyElement.classList.remove(hideClass);
        stickyElement.classList.add(animateClass);
      }
      if (top <= topVal) {
        stickyElement.classList.remove(stickyClass);
      }
      if (top > topVal && top > lastScrollTop) {
        stickyElement.classList.add(hideClass);
        stickyElement.classList.add(animateClass);
        stickyElement.classList.add(stickyClass);
      }
      lastScrollTop = top;
    }, 250)
  );
};

export default stickyNav;
