import { h, RenderableProps } from "preact";

interface ISearchLoadingProps {
  loading: boolean;
}

const SearchLoading = ({ loading }: RenderableProps<ISearchLoadingProps>) => (
  <div
    className={`search-loading-spinner ${
      loading ? "search-loading-spinner--loading" : ""
    }`}
  >
    <div className="loader" />
  </div>
);

export default SearchLoading;
