import { tns } from "tiny-slider/src/tiny-slider";

function initSummarySlider() {
  if (document.querySelector(".summary-slider") != null) {
    tns({
      container: ".summary-slider",
      loop: true,
      items: 1,
      controlsContainer: ".summary-panel__controls",
      nav: false,
      autoplay: false,
      speed: 400,
      autoplayButtonOutput: false,
      mouseDrag: true,
      disable: false,
      responsive: {
        700: {
          disable: true
        }
      }
    });
  }
}

export default initSummarySlider;
