function nav() {
  let toggleMenu = document.querySelector(".js-toggle-menu") as HTMLElement;
  if (toggleMenu) {
    toggleMenu.addEventListener("click", e => {
      e.preventDefault();
      document.body.classList.toggle("mobileMenuOpen");
      let nav = document.querySelector(".primary-navigation") as HTMLElement;
      nav.classList.toggle("primary-navigation__mobile-active");
      let spin = document.querySelector(".hamburger--spin") as HTMLElement;
      spin.classList.toggle("is-active");
    });
  }
}

export default nav;
