import debounce from "lodash.debounce";
import setElementHeight from "./utils/setElementHeight";
const mediaQuery = window.matchMedia("(max-width: 720px)");

let isSecondLevelOpen = false;
let haveEventListenersBeenSetUp = false;
const navigationElement = document.querySelector(
  ".primary-navigation"
) as HTMLElement;
const header = document.querySelector(".primary-header") as HTMLElement;
const setMobileNavigationHeight = setElementHeight(navigationElement, header);
const debouncedSetMobileNavigationHeight = debounce(
  setMobileNavigationHeight,
  100
);

function toggleSecondLevelMenu(menuDropdown, primaryTrigger, secondaryTrigger) {
  return function() {
    const menuSecondLevel = menuDropdown;
    isSecondLevelOpen = !isSecondLevelOpen;
    menuSecondLevel.classList.add("primary-navigation__mega--animate");
    menuSecondLevel.classList.toggle("primary-navigation__mega--mobile-active");
    if (isSecondLevelOpen) {
      primaryTrigger.setAttribute("aria-expanded", true);
      secondaryTrigger.setAttribute("aria-expanded", true);
    } else {
      primaryTrigger.setAttribute("aria-expanded", false);
      secondaryTrigger.setAttribute("aria-expanded", false);
    }
  };
}

function setUpMobileMenu(list, toggle) {
  // guard - prevent multiple event listeners being setup when switching between breakpoints
  setMobileNavigationHeight();
  window.addEventListener("resize", debouncedSetMobileNavigationHeight);
  if (!haveEventListenersBeenSetUp && list) {
    haveEventListenersBeenSetUp = true;
    //window.addEventListener("resize", debounce(setMobileNavigationHeight, 100));
    list.forEach(item => {
      const openSecondLevel = item.querySelectorAll(
        ".js-mobile-second-level-trigger-forward"
      ) as HTMLCollectionOf<Element>;
      const closeSecondLevel = item.querySelector(
        ".js-mobile-second-level-trigger-back"
      ) as HTMLElement;
      const menuDropdown = item.querySelector(".js-drop-down") as HTMLElement;
      for (let i = 0; i < openSecondLevel.length; i++) {
        if (openSecondLevel[i] && closeSecondLevel && menuDropdown) {
          openSecondLevel[i].addEventListener(
            "click",
            toggle(menuDropdown, openSecondLevel, closeSecondLevel)
          );
          closeSecondLevel.addEventListener(
            "click",
            toggle(menuDropdown, closeSecondLevel, openSecondLevel)
          );
        }
      }
    });
  }
}

function unbindMobileMenu() {
  debouncedSetMobileNavigationHeight.cancel();
  window.removeEventListener("resize", debouncedSetMobileNavigationHeight);
  navigationElement.style.height = "auto";
}

function responsiveMenuBreakpoint(menuItem) {
  return function(mediaQuery) {
    if (mediaQuery.matches) {
      setUpMobileMenu(menuItem, toggleSecondLevelMenu);
    } else {
      unbindMobileMenu();
    }
  };
}

function mobileMenu(menuItem) {
  const responsiveMenu = responsiveMenuBreakpoint(menuItem);
  // on init
  responsiveMenu(mediaQuery);
  // on change
  mediaQuery.addEventListener("change", () => {
    console.log("test");
    responsiveMenu(mediaQuery);
  });
}

export default mobileMenu;
