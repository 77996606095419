import { h, render, Component } from "preact";
import { IMember, IRegion, IRenderRegion, IRenderCountry } from "../interfaces";
import "jspolyfill-array.prototype.find";

import Region from "./region";

interface IMemberDirectoryProps {
  members: IMember[];
  regions: IRegion[];
}

type MembersByCountryCode = { [code: string]: IMember[] };

interface IMemberDirectoryState {
  region?: IRenderRegion;
  country?: IRenderCountry;
  regions: IRenderRegion[];
  pageSize: number | false;
}

class MemberDirectory extends Component<
  IMemberDirectoryProps,
  IMemberDirectoryState
> {
  initialState: IMemberDirectoryState = {
    pageSize: 50,
    regions: []
  };

  constructor(props: IMemberDirectoryProps) {
    super(props);
    this.state = this.initialState;
    this.setupData();
  }

  setupData = () => {
    let membersByCountryCode = this.props.members.reduce(
      (groups, m) => {
        if (!groups[m.country.code]) {
          groups[m.country.code] = [];
        }
        groups[m.country.code].push(m);
        return groups;
      },
      {} as MembersByCountryCode
    );
    for (let code in membersByCountryCode) {
      membersByCountryCode[code].sort((m1, m2) =>
        m1.name.localeCompare(m2.name)
      );
    }

    let regions = this.props.regions
      .map(
        r =>
          ({
            ...r,
            countries: r.countries
              .filter(c => !!membersByCountryCode[c.code])
              .map(c => ({
                ...c,
                members: membersByCountryCode[c.code]
              }))
          } as IRenderRegion)
      )
      .filter(r => r.countries.length > 0);

    this.setState({
      regions
    });
  };

  handleRegionChange = event => {
    const region = this.state.regions.find(r => r.name === event.target.value);
    let country = this.state.country;
    if (region && country) {
      const c = country;
      // we've selected a region that doens't contain the currently selected country, so unselect the country
      if (!region.countries.find(rc => rc.code === c.code)) {
        country = undefined;
      }
    }
    this.setState({ region, country, pageSize: this.initialState.pageSize });
    let target = document.getElementById("js-member-directory");
    if (target) {
      window.scrollTo({
        top: target.offsetTop,
        behavior: "smooth"
      });
    }
  };

  handleCountryChange = event => {
    let country: IRenderCountry | undefined;
    for (let region of this.state.regions) {
      country = region.countries.find(c => c.code === event.target.value);
      if (country) {
        break;
      }
    }
    this.setState({ country, pageSize: this.initialState.pageSize });
    let target = document.getElementById("js-member-directory");
    if (target) {
      window.scrollTo({
        top: target.offsetTop,
        behavior: "smooth"
      });
    }
  };

  // deep clone of state regions
  cloneRegions = (regions: IRenderRegion[]): IRenderRegion[] => {
    return regions.slice().map(r => ({ ...r }));
  };

  applyFiltering = (
    regions: IRenderRegion[],
    region?: IRenderRegion,
    country?: IRenderCountry
  ): IRenderRegion[] => {
    if (region) {
      regions = [region];
    }
    if (country) {
      regions = this.cloneRegions(regions);
      const r = regions.find(
        r => !!r.countries.find(c => c.code === country.code)
      );
      if (r) {
        regions = [r];
        r.countries = [country];
      }
    }
    return regions;
  };

  applyPagination = (
    regions: IRenderRegion[],
    pageSize: number
  ): IRenderRegion[] => {
    let memberCount = 0;
    let limitReached = false;
    const paginatedRegions: IRenderRegion[] = [];
    regions = this.cloneRegions(regions);
    for (const r of regions) {
      const memberRegion: IRenderRegion = { ...r, countries: [] };
      for (const c of r.countries) {
        const regionCountry: IRenderCountry = { ...c };
        memberCount += regionCountry.members.length;
        if (memberCount > pageSize) {
          limitReached = true;
          regionCountry.members = regionCountry.members.slice(
            0,
            regionCountry.members.length - (memberCount - pageSize)
          );
        }
        if (regionCountry.members.length > 0)
          memberRegion.countries.push(regionCountry);
        if (limitReached) {
          break;
        }
      }
      if (memberRegion.countries.length > 0)
        paginatedRegions.push(memberRegion);
      if (limitReached) {
        break;
      }
    }
    return paginatedRegions;
  };

  countMembers = (regions: IRenderRegion[]): number => {
    return regions.reduce(
      (regionTotal, r) =>
        regionTotal +
        r.countries.reduce(
          (countryTotal, c) => countryTotal + c.members.length,
          0
        ),
      0
    );
  };

  handleShowAllClick = () => {
    this.setState({
      pageSize: false
    });
  };

  renderPagination = (
    regions: IRenderRegion[],
    paginatedRegions: IRenderRegion[]
  ) => {
    const { country, region } = this.state;

    const visibleMemberCount = this.countMembers(paginatedRegions);
    const totalMemberCount = this.countMembers(regions);

    let inLocation = "";
    if (country) {
      inLocation = ` in ${country.name}`;
    } else if (region) {
      inLocation = ` in ${region.name}`;
    }

    let all = "";
    let ofTotal = "";
    if (visibleMemberCount < totalMemberCount) {
      ofTotal = ` of ${totalMemberCount}`;
    } else {
      all = " all";
    }

    return (
      <div className="constrain constrain--narrow">
        <p>
          Showing{all} {visibleMemberCount}
          {ofTotal} members
          {inLocation}
        </p>
        {visibleMemberCount < totalMemberCount && (
          <p>
            <button
              className="btn btn--primary"
              onClick={this.handleShowAllClick}
            >
              Show all members{inLocation}
            </button>
          </p>
        )}
      </div>
    );
  };

  render() {
    const { country, region, regions, pageSize } = this.state;
    let dropdownRegions = regions;
    let memberRegions = this.cloneRegions(regions);
    let filteredMemberRegions = this.applyFiltering(
      memberRegions,
      region,
      country
    );
    let paginatedMemberRegions = filteredMemberRegions;
    if (pageSize !== false) {
      paginatedMemberRegions = this.applyPagination(
        filteredMemberRegions,
        pageSize
      );
    }
    if (region) {
      dropdownRegions = [region];
    }

    return (
      <section className="directory">
        <header className="directory__header">
          <div className="constrain constrain--narrow directory__header-inner">
            <select
              value={region ? region.name : ""}
              onChange={this.handleRegionChange}
              aria-label="Select region"
              className="directory__filter-dropdown"
            >
              <option value="">Select region</option>
              {regions.map(r => (
                <option key={r.name} value={r.name}>
                  {r.name}
                </option>
              ))}
            </select>
            <select
              value={country ? country.code : ""}
              onChange={this.handleCountryChange}
              aria-label="Select country"
              className="directory__filter-dropdown"
            >
              <option value="">Select country</option>
              {dropdownRegions.map(r => (
                <optgroup key={r.name} label={r.name}>
                  {r.countries.map(c => (
                    <option key={c.code} value={c.code}>
                      {c.name}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
          </div>
        </header>

        <div className="constrain constrain--narrow">
          {paginatedMemberRegions.map(r => (
            <Region key={r.name} region={r} />
          ))}
        </div>

        {this.renderPagination(filteredMemberRegions, paginatedMemberRegions)}
      </section>
    );
  }
}

function initialise(element: HTMLElement) {
  const config = JSON.parse(element.dataset.directory || "");

  return render(<MemberDirectory {...config} />, element);
}

export default initialise;
