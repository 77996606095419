import loadJS from "./loadjs";

declare global {
  interface Window {
    HTMLPictureElement: any;
  }
}

export default function() {
  if (
    !window.HTMLPictureElement ||
    !("sizes" in document.createElement("img"))
  ) {
    document.createElement("picture");
    loadJS("/scripts/dist/norev/picturefill.min.js");
  }
}
