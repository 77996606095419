import { h, render, Component } from "preact";
import { IMember, IRegion } from "../interfaces";

import MemberMap from "./member-map";

interface IMemberMapAppProps {
  accessToken: string;
  styleUrl: string;
  members: IMember[];
  regions: IRegion[];
  memberDirectoryUrl: string;
}

interface IMemberMapAppState {
  selectedRegion?: IRegion;
}

class MemberMapApp extends Component<IMemberMapAppProps, IMemberMapAppState> {
  state: IMemberMapAppState = {};

  constructor(props) {
    super(props);
  }

  handleRegionChange = event => {
    const selectedRegion = this.props.regions.find(
      r => r.name === event.target.value
    );
    this.setState({ selectedRegion });
  };

  render() {
    const { accessToken, members, regions, styleUrl } = this.props;
    const { selectedRegion } = this.state;
    return (
      <div className="member-map">
        <div class="constrain">
          <div class="member-map__controls">
            <select
              value={selectedRegion ? selectedRegion.name : ""}
              onChange={this.handleRegionChange}
              aria-label="Select map region"
            >
              <option value="">Select map region</option>
              {regions.map(r => (
                <option key={r.name} value={r.name}>
                  {r.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="member-map__map">
          <MemberMap
            {...{ accessToken, members, regions, styleUrl, selectedRegion }}
          />
        </div>

        <div className="member-map__btn-container">
          <a
            class="member-map__view-directory btn btn--primary"
            href={this.props.memberDirectoryUrl}
          >
            View full member directory
          </a>
        </div>
      </div>
    );
  }
}

function initialise(element: HTMLElement) {
  const config = JSON.parse(element.dataset.mapConfig || "");

  if (!element || !config) return;

  return render(<MemberMapApp {...config} />, element);
}

export default initialise;
