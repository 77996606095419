const hasOverflow = (element: HTMLElement) => {
  return element.offsetHeight < element.scrollHeight;
};
function truncation() {
  if (document.querySelector(".person-info") != null) {
    const personList = document.querySelectorAll(".person");
    personList.forEach(person => {
      const button = <HTMLElement>person.querySelector(".show-more");
      const info = <HTMLElement>person.querySelector(".person-info");
      const paragraph = <HTMLElement>info.querySelector("p");
      let active = false;
      if (hasOverflow(paragraph)) {
        button.style.display = "block";
        info.classList.add("person-info-gradient");
      }
      if (button != null) {
        button.addEventListener("click", () => {
          info.classList.toggle("person-info-gradient");
          active = !active;
          button.innerText = active ? "Read less" : "Read more";
          if (info != null) {
            info.classList.toggle("expand");
          }
        });
      }
    });
  }
}

export default truncation;
