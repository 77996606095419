import { h, RenderableProps } from "preact";
import ReviewArticle, { IReviewArticleProps } from "./ReviewArticle";

export interface IReviewArticleListProps {
  articles: IReviewArticleProps[];
}

export const ReviewArticleList = ({
  articles
}: RenderableProps<IReviewArticleListProps>) => (
  <ul class="card-list">
    {articles.map(article => (
      <ReviewArticle {...article} />
    ))}
  </ul>
);

export default ReviewArticleList;
