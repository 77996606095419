import { h, render, Component } from "preact";
import { ReviewNav } from "../components/reviews/reviewNav";
import { ReviewArticleList } from "../components/reviews/ReviewArticleList";
import { IReviewArticleProps } from "../components/reviews/ReviewArticle";
import ReviewPagination from "../components/reviews/reviewPagination";
import "lazysizes/plugins/attrchange/ls.attrchange";

export interface IReviewPageProps {
  content: string;
  themes: string[];
  recordLimit: number;
  selectedTheme: string;
}

export interface IReviewPageState {
  recordOffset: number;
  selectedTheme: string;
  articlesList: IReviewArticlesList;
}

export interface IReviewArticlesList {
  articles: IReviewArticleProps[];
  totalResults: number;
}

export class Reviews extends Component<IReviewPageProps, IReviewPageState> {
  initialState: IReviewPageState = {
    recordOffset: 0,
    selectedTheme: this.props.selectedTheme,
    articlesList: {
      articles: [],
      totalResults: 0
    }
  };

  constructor(props: IReviewPageProps) {
    super(props);
    this.state = this.initialState;
    this.selectTheme = this.selectTheme.bind(this);
    this.selectPage = this.selectPage.bind(this);
    this.loadArticles();
  }

  async loadArticles() {
    const { selectedTheme, recordOffset } = this.state;
    const { recordLimit } = this.props;

    const response = await fetch(
      `/umbraco/Api/Review/GetReviews?theme=${selectedTheme}&offset=${recordOffset}&limit=${recordLimit}`
    );

    const json = await response.json();
    const articlesList: IReviewArticlesList = json as IReviewArticlesList;
    this.setState({ articlesList: articlesList });
  }

  async selectTheme(e: any) {
    e.preventDefault();
    const selectedTheme = e.target.text;
    this.setState({ selectedTheme: selectedTheme, recordOffset: 0 });
    this.loadArticles();

    history.replaceState("", "", location.pathname + "?theme=" + selectedTheme);
  }

  async selectPage(e: any) {
    e.preventDefault();

    const { rel, text } = e.target;
    const { recordLimit } = this.props;
    const { recordOffset, articlesList } = this.state;

    var newRecordOffset: number;

    if (rel == "prev") {
      newRecordOffset = recordOffset - recordLimit;

      //validate offset
      if (newRecordOffset < 0) {
        newRecordOffset = 0;
      }
    } else if (rel == "next") {
      newRecordOffset = recordOffset + recordLimit;

      //validate offset
      if (newRecordOffset > articlesList.totalResults) {
        newRecordOffset = articlesList.totalResults;
      }
    } else {
      newRecordOffset = parseInt(text) * recordLimit - recordLimit;
    }

    this.setState({
      recordOffset: newRecordOffset
    });
  }

  componentDidMount() {
    //this.loadArticles();
  }

  componentDidUpdate({}, prevState: IReviewPageState) {
    const { recordOffset, selectedTheme } = this.state;

    if (
      prevState.recordOffset !== recordOffset ||
      prevState.selectedTheme !== selectedTheme
    ) {
      this.loadArticles();
    }
  }

  render() {
    const { content, themes, recordLimit } = this.props;
    const { selectedTheme, articlesList, recordOffset } = this.state;

    return (
      <div>
        <p class="review-intro">{content}</p>
        <ReviewNav
          themes={themes}
          selectedTheme={selectedTheme}
          buttonHandler={this.selectTheme}
        />
        <ReviewArticleList articles={this.state.articlesList.articles} />
        <ReviewPagination
          recordOffset={recordOffset}
          recordLimit={recordLimit}
          totalRecords={articlesList.totalResults}
          buttonHandler={this.selectPage}
        />
      </div>
    );
  }
}

function getParamByName(name) {
  var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
    window.location.search
  );
  if (results == null) {
    return null;
  }
  results[1] = results[1].replace(/-/g, " ");
  return decodeURI(results[1]) || 0;
}

async function initialise(element: HTMLElement) {
  const response = await fetch("/umbraco/Api/Review/GetPageContent");
  const json = await response.json();
  const data: IReviewPageProps = json as IReviewPageProps;

  var theme = getParamByName("theme") || "All";

  return render(
    <Reviews
      content={data.content}
      themes={data.themes}
      recordLimit={data.recordLimit}
      selectedTheme={theme}
    />,
    element
  );
}

export default initialise;
