function setElementHeight(element, offSet) {
  return function() {
    let innerHeight = window.innerHeight;
    if (offSet) {
      innerHeight -= offSet.clientHeight;
    }
    element.style.height = innerHeight + "px";
  };
}

export default setElementHeight;
