type size = "XS" | "S" | "M" | "L" | "XL" | "XXL";
type sizeMap = Record<size, number>;

// copy of sizes in variables.scss
export const sizes: sizeMap = {
  XS: 320,
  S: 480,
  M: 720,
  L: 980,
  XL: 1024,
  XXL: 1280
};

const mq = (from: size): boolean => {
  return window.matchMedia(`(min-width: ${sizes[from]}px)`).matches;
};

export default mq;
