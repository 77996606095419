import { h, render, Component } from "preact";
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";
import { fetch } from "whatwg-fetch";

// use native browser implementation if it supports aborting
const abortableFetch = "signal" in new Request("") ? window.fetch : fetch;

import debounce from "lodash.debounce";

import { IPaging } from "../interfaces";
import { IPublication } from "./interfaces";
import SVGIcon from "../components/svgIcon";
import SearchPaging from "../components/searchPaging";
import SearchLoading from "../components/searchLoading";
import Result from "./result";

interface IPublicationsProps {
  searchUrl: string;
}

interface IPublicationResults {
  query: string;
  results: IPublication[];
  paging: IPaging;
}

interface IPublicationsState {
  query: string;
  results?: IPublicationResults;
  page: number;
  loading: boolean;
}

class Publications extends Component<IPublicationsProps, IPublicationsState> {
  pageSize = 20;
  inputEl: HTMLInputElement | null = null;
  pagingInfoEl: HTMLInputElement | null = null;
  abortController: AbortController | null = null;
  initialState: IPublicationsState = {
    query: "",
    page: 1,
    results: undefined,
    loading: false
  };

  constructor(props: IPublicationsProps) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    this.fetchResults();
  }

  handleQueryChange = async () => {
    const query = (this.inputEl && this.inputEl.value) || "";
    if (this.state.query !== query) {
      this.setState({ query, page: 1 });
      await this.fetchResults(query);
    }
  };

  handleKeyPress = (e: KeyboardEvent) => {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  };

  fetchResults = debounce(
    async (
      query: string | undefined,
      page: number = 1
    ): Promise<IPublicationResults | undefined> => {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();

      this.setState({
        loading: true
      });
      let results: IPublicationResults | undefined;
      try {
        const response = await abortableFetch(
          `${this.props.searchUrl}?query=${query || ""}&page=${page}&pageSize=${
            this.pageSize
          }`,
          { signal: this.abortController.signal }
        );
        results = (await response.json()) as IPublicationResults;
        this.setState({
          results,
          loading: false
        });
      } catch (e) {
        if (e.name !== "AbortError") {
          this.setState({
            loading: false
          });
        }
      }
      return results;
    },
    150
  );

  handleGoToPage = page => () => {
    if (
      page < 1 ||
      !this.state.results ||
      page > this.state.results.paging.totalPages
    ) {
      return;
    }
    this.setState({ page });
    this.fetchResults(this.state.query, page);
    if (this.pagingInfoEl) {
      this.pagingInfoEl.scrollIntoView({ behavior: "smooth" });
    }
  };

  renderPagingParagraph = (results: IPublicationResults) => {
    const first =
      results.paging.pageSize * (results.paging.currentPage - 1) + 1;
    const last = Math.min(
      first + results.paging.pageSize - 1,
      results.paging.totalResults
    );
    return (
      <p
        className="publications__info-message"
        ref={c => (this.pagingInfoEl = c)}
      >
        Showing publications{" "}
        <strong>
          {first} - {last}
        </strong>{" "}
        of <strong>{results.paging.totalResults}</strong>
        {results.query ? ` for your search "${results.query}"` : ""}
      </p>
    );
  };

  renderNoResults = (results: IPublicationResults) => {
    return (
      <p className="publications__info-message">
        There were no results for <strong>{results.query}</strong>
      </p>
    );
  };

  renderResults = (results: IPublicationResults) => {
    if (!results.results || !results.results.length) {
      return this.renderNoResults(results);
    }
    return (
      <div>
        {this.renderPagingParagraph(results)}
        <ul role="list">
          {results.results.map(r => (
            <li
              key={r.url}
              role="listitem"
              className="search-result search-result--publication"
            >
              <Result result={r} />
            </li>
          ))}
        </ul>
        <SearchPaging
          goToPage={this.handleGoToPage}
          paging={results.paging}
          showNumberLinks={true}
          className="search-paging--unpad"
        />
      </div>
    );
  };

  render() {
    const { results, query, loading } = this.state;
    return (
      <div className="constrain">
        <div className="publications">
          <p className="publications__input-help">
            Type something into the search box to start searching through our
            publications archive. You can search by title, author, publication
            type or year.
          </p>
          <form
            className="publications__form"
            role="search"
            onSubmit={e => e.preventDefault()}
          >
            <input
              type="search"
              value={query}
              ref={c => (this.inputEl = c)}
              className="publications__input"
              placeholder="Search for a publication..."
              onInput={this.handleQueryChange}
              onKeyUp={this.handleKeyPress}
              autocomplete="off"
              aria-label="Search the site"
              aria-controls="publications-results"
            />
            <SVGIcon name="search" className="publications__search-icon" />
          </form>
          <div className="publications__results-container">
            <div
              id="publications-results"
              className="publications__results"
              aria-live="polite"
            >
              <SearchLoading loading={loading} />
              {results && this.renderResults(results)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function initialise(element: HTMLElement) {
  const searchUrl = element.dataset.searchUrl;
  if (!searchUrl) return;

  return render(<Publications searchUrl={searchUrl} />, element);
}

export default initialise;
