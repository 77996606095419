import { h, RenderableProps } from "preact";

import { ISearchResult } from "./interfaces";

import SVGIcon from "../components/svgIcon";

type DocTypeAlias = "contentPage" | "newsArticlePage" | "eventPage" | "File";

interface IFileType {
  iconName: string;
  name: string;
}

type FileTypeMap = { [docType in DocTypeAlias]: IFileType };

const fileTypes: FileTypeMap = {
  contentPage: {
    iconName: "page",
    name: "Page"
  },
  eventPage: {
    iconName: "event",
    name: "Event"
  },
  newsArticlePage: {
    iconName: "article",
    name: "Article"
  },
  File: {
    iconName: "download",
    name: "File"
  }
};

interface ISearchResultProps {
  result: ISearchResult;
}

const SearchResult = ({ result }: RenderableProps<ISearchResultProps>) => {
  const fileType: IFileType =
    fileTypes[result.type] || fileTypes["contentPage"];
  return (
    <a href={result.url} className="search-result__link">
      <h3
        dangerouslySetInnerHTML={{ __html: result.name }}
        className="search-result__title"
      />
      <span className="search-result__url">{result.url}</span>
      {result.highlight && (
        <p
          dangerouslySetInnerHTML={{ __html: result.highlight }}
          className="search-result__description"
        />
      )}
      <label className="search-result__type">
        <SVGIcon
          className="search-result__type-icon"
          name={fileType.iconName}
        />
        <span className="search-result__type-text">{fileType.name}</span>
      </label>
    </a>
  );
};

export default SearchResult;
